import React, { Fragment } from "react";

import clsx from "clsx";

type AppHeaderLayoutProps = {
	left?: React.ReactNode;
	center?: React.ReactNode;
	children?: React.ReactNode;
	right?: React.ReactNode;
};

const AppHeaderLayout = ({
	left,
	center,
	children,
	right,
	...rest
}: AppHeaderLayoutProps) => (
	<Fragment>
		<div
			className="max-w-container-lg mx-auto h-[60px] w-full px-5 lg:h-[75px]"
			{...rest}
		>
			<div className="flex h-full items-center gap-6 xl:gap-12">
				{left}
				{center}
				{right}
			</div>
		</div>
		{children}
	</Fragment>
);

const AppHeaderBorder = ({ className = "", ...props }) => (
	<div
		className={clsx("border-nav h-[1px] w-full border-b", className)}
		{...props}
	/>
);

const AppHeader = ({ className = "", ...props }) => (
	<header
		role="navigation"
		className={clsx(
			"z-header border-app-header bg-app-header sticky top-[var(--banner-height)] w-full border-b transition-all duration-200",
			className,
		)}
		{...props}
	/>
);

AppHeader.Layout = AppHeaderLayout;

AppHeader.Border = AppHeaderBorder;

export default AppHeader;
